import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatDialog, MatPaginator, MatSortable, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { AdminApprovalComponent } from '../admin-approval/admin-approval.component';
import { RestService } from '../common/rest.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
  displayedColumns: string[] = [
    'gid',
    'name',
    'emailId',
    'role', 'project', 'application', 'status', 'action'];
  dataSource: MatTableDataSource<any>;
  userList: any[];
  sortedData: any;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private toastr: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private restApi: RestService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.prepareManageUserData();
  }

  prepareManageUserData() {
    this.spinner.show();
    this.restApi.getUsers().subscribe((data: any[]) => {
      this.userList = data;
      this.dataSource = new MatTableDataSource(this.userList);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.customFilterPredicate();
      this.spinner.hide();
      this.sort.sort({ id: 'role', start: 'asc' } as MatSortable);
      this.sort.sort({ id: 'role', start: 'desc' } as MatSortable);
    }, error => {
      this.spinner.hide();
      this.toastr.warning(error.error.message);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter): boolean => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
    return myFilterPredicate;
  }

  sortData(sort: Sort) {
    const data = this.userList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.lastName, b.lastName, isAsc);
        case 'role': return this.compare(a.project.role, b.project.role, isAsc);
        case 'project': return this.compare(a.project.detail.name, b.project.detail.name, isAsc);
        default: return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDialog(userData): void {
    const dialogRef = this.dialog.open(AdminApprovalComponent, {
      width: '37.5rem',
      data: { userData }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.prepareManageUserData();
      }
    });
  }
}
