export const appConfigDetails = {
  appUrls: { // Need to replace this with actual url of the applications
    ShiftLogBook: '/shiftlogbook',
    'FlexiChecklist-Mechanical': '/fleximechportal',
    FlexiChecklist: '/flexiportal',
    Photos: '/photosweb',
    'Lesson-Learned':'https://apex.oci.siemens-energy.com/ark/f?p=30340:26:14775072765661'
  },
  mobileApps: {
    Flexi: false,
    ShiftLogBook: false,
    FQP: true,
    LOP: true,
    Photos: false,
    Piping: true
  }
};
