import { Component, OnInit } from "@angular/core";
import { RestService } from "../common/rest.service";
import { Router } from "@angular/router";
import { Project } from "../manage-project/manage-project.component";
import { ApplicationData } from "../manage-applications/manage-applications.component";
import { appConfigDetails } from "./constants/applicationsConfig";
import { ToastrService } from "ngx-toastr";
import { commonConstants } from "../common/constants/constants";
import { HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";

export interface UserProject {
  role: string;
  departmentName: string;
  detail: Project;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  appPanelAccess = false;
  projectPanelAccess = false;
  summaryAccess = false;
  projects: UserProject[];
  applications: ApplicationData[];
  applicationSummary: any;
  appUrls = appConfigDetails.appUrls;
  mobileApps = appConfigDetails.mobileApps;
  loginMsg = commonConstants.loginErrorMsg;

  constructor(
    private router: Router,
    private restService: RestService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.restService.getProjectsApps().subscribe(
      (data) => {
        console.log("In Dashboard - Projects Apps");
        if (data.response !== null) {
          this.spinner.hide();
          this.projects = data.response.userProjects;
          this.applications = data.response.applications;
          this.applicationSummary = data.response.applications;

          this.sortApplications();
          if (this.projects.length > 0) {
            this.projectPanelAccess = true;
          }
          if (this.applications.length > 0) {
            this.appPanelAccess = true;
          } else {
            this.summaryAccess = true;
          }
        } else {
          this.spinner.hide();
          this.toastr.error(data.message);
        }
      },
      (error) => {
        this.toastr.error(this.loginMsg);
        this.spinner.hide();
        this.toastr.error(this.loginMsg);
        if (typeof error.error.error !== "undefined") {
          if (error.error.error === "Unauthorized") {
            this.toastr.error(this.loginMsg);
          } else {
            this.toastr.error(error.error.error);
          }
        }
        if (error instanceof HttpErrorResponse) {
          this.toastr.error(error.error.message);
        }
      }
    );
  }

  sortApplications(): void {
    this.applications.sort((a, b) => {
      const isMobileA = this.mobileApps[a.name] ? 1 : 0;
      const isMobileB = this.mobileApps[b.name] ? 1 : 0;

      // Sort by mobile apps first
      if (isMobileA !== isMobileB) {
        return isMobileB - isMobileA;
      }

      // If both are mobile or both are not mobile, sort by name
      return a.name.localeCompare(b.name);
    });
  }
}
