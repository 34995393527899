import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../common/rest.service';
import { RequestConfirmationDialogComponent } from '../request-confirmation-dialog/request-confirmation-dialog.component';

@Component({
  selector: 'app-admin-approval',
  templateUrl: './admin-approval.component.html',
  styleUrls: ['./admin-approval.component.scss']
})
export class AdminApprovalComponent implements OnInit {
  approvalForm: FormGroup;
  applicationArray: any = new Array();
  projects: any;
  roles: any;
  statusValues: any;
  showStatus = false;
  requestedApplicationArray = [];
  isApplicationCheckedArray = [];
  formArrayApplicationValues = [];
  unselectedApplicationArray = new Array();
  actionSelectedApplicationArray = new Array();
  clicked = false;
  readonly = 'true';
  superAdminOption = false;
  dialogResult = false;
  checkRemarksEmpty = false;

  constructor(private restService: RestService, private toastr: ToastrService, private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AdminApprovalComponent>,
              public confirmDialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getApplications(this.data.userData.project.detail.name);
    // this.getProjects();
    this.getRequestedApplication(this.data.userData.project.detail.applicationName);
    this.getRoles();
    this.getStatus();
    this.checkForStatus(this.data.userData);
    this.checkForRemarks(this.data.userData.project.detail.remarks);

    this.approvalForm = this.fb.group({
      name: this.data.userData.lastName + ', ' + this.data.userData.firstName,
      emailId: this.data.userData.emailId,
      gid: this.data.userData.gid,
      role: this.data.userData.project.role,
      projectName: this.data.userData.project.detail.name,
      status: 'ACTIVE',
      department: 'Civil Construction',
      approved: 'true',
      applicationUnselected: [],
      actionSelected: [],
      remarks:[null, Validators.required] 
    });
    this.approvalForm.setControl('applicationName', this.fb.array(this.requestedApplicationArray));
    this.checkForSuperAdmin(this.approvalForm.value.role);
  }

  checkForRemarks(data: any) {
    if (!data) {
      this.checkRemarksEmpty = true;
    }
  }
  checkForStatus(data: any) {
    if (data.status) {
      this.showStatus = true;
    }
  }

  checkForSuperAdmin(roleDesired: any) {
    if (roleDesired === 'Super Admin') {
      this.superAdminOption = true;
    }
   }

  getRequestedApplication(data) {
    this.requestedApplicationArray = data;
  }

  checkApplicationValues(applicationArray, formArray) {
    for (const form of formArray) {
      this.formArrayApplicationValues.push(form);
    }

    for (const app of applicationArray) {
      if (this.formArrayApplicationValues.includes(app)) {
        this.isApplicationCheckedArray.push({ applicationName: app, checkboxValue: true });
      } else {
        this.isApplicationCheckedArray.push({ applicationName: app, checkboxValue: false });
      }
    }

  }

  getStatus() {
    this.statusValues = ['ACTIVE', 'INACTIVE'];
  }
  getApplications(projectName: any) {
    this.restService.getProjectUserApps(projectName).subscribe(result => {

          this.applicationArray = result;

          this.checkApplicationValues(this.applicationArray, this.requestedApplicationArray);
    });
  }

  getRoles() {
    this.restService.getRoles().subscribe(d => { this.roles = d; });
  }
  onChangeEventFunc(applicationName: string, isChecked: boolean) {

    this.checkClicked();
    if (this.approvalForm.controls.applicationName) {
       this.applicationArray = this.formArrayApplicationValues;
     }
    if (isChecked) {
      this.applicationArray.push(applicationName);
      this.isApplicationCheckedArray.find(item => item.applicationName === applicationName).checkboxValue = true;
      this.actionSelectedApplicationArray.push(applicationName);
      const index = this.unselectedApplicationArray.indexOf(applicationName);
      if ( index >= 0) {
      this.unselectedApplicationArray.splice(index, 1);
      }
    } else {
      this.unselectedApplicationArray.push(applicationName);
      console.log(this.unselectedApplicationArray);
      const index = this.applicationArray.indexOf(applicationName);
      this.applicationArray.splice(index, 1);
      this.isApplicationCheckedArray.find(item => item.applicationName === applicationName).checkboxValue = false;
      const indexSelected = this.actionSelectedApplicationArray.indexOf(applicationName);
      if ( indexSelected >= 0) {
      this.actionSelectedApplicationArray.splice(indexSelected, 1);
      }
    }
  }

  checkClicked() {
    this.clicked = true;
  }

  openDialog(title: string, message: string): void {
    const dialogConfirmRef = this.confirmDialog.open(RequestConfirmationDialogComponent, {
      width: '38%',
      data: {
        title,
        message
      }
    });

    dialogConfirmRef.afterClosed().subscribe(result => {
      this.dialogResult = result;
      if (this.dialogResult) {
        this.request(); // Sending Request to Server
        this.dialogResult = false;
      }
    });
  }
  onAccept() {
    // Process PROJECT data here
    this.approvalForm.get('remarks').clearValidators();
    this.approvalForm.get('remarks').updateValueAndValidity();    
    let message = 'Do you want to make the user inactive ?';
    if (this.approvalForm.value.status === 'ACTIVE') {
      if (this.applicationArray.length === 0) {
        message = 'Application is not selected. Do you wish to continue ? ';
        this.openDialog('Confirmation', message);
      } else {
        this.request();
      }
    } else {
      message = 'Inactivating will revoke ' + this.approvalForm.value.name + '\'s access. Do you wish to continue ?';
      this.openDialog('Confirmation', message);
    }

  }


  request() {
    if (this.clicked) {
      this.approvalForm.setControl('applicationName', this.fb.array(this.applicationArray));
      console.log('inside the request call ', this.unselectedApplicationArray);
      this.approvalForm.setControl('applicationUnselected', this.fb.array(this.unselectedApplicationArray));
      this.approvalForm.setControl('actionSelected', this.fb.array(this.actionSelectedApplicationArray));
    }

    if (this.approvalForm.invalid) {
      return;
    }

    this.restService.updatePermission(this.approvalForm.value)
      .subscribe((res) => {
        this.toastr.success(res.message);
        this.onClose(true);
        // window.top.location.reload();
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  onSubmit() {
    this.approvalForm.setControl('applicationName', this.fb.array(this.requestedApplicationArray));
    this.approvalForm.value.approved = 'false';

    if (this.approvalForm.invalid) {
      return;
    }

    this.restService.updatePermission(this.approvalForm.value)
      .subscribe((res) => {
        let msg = res.message;
        if (res.message === 'Approval Successful! Permissions are successfully updated') {
          msg = 'Rejected successfully! ';
        }

        this.toastr.success(msg);
        this.onClose(true);
        // window.top.location.reload();
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }

}
