import { Routes, RouterModule } from '@angular/router';
import { ManageProjectComponent } from './manage-project/manage-project.component';
import { UpdateProjectComponent } from './update-project/update-project.component';
import { ManageApplicationsComponent } from './manage-applications/manage-applications.component';
import { CreateApplicationComponent } from './create-application/create-application.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { ManageRoleComponent } from './manage-role/manage-role.component';
import { AddUserComponent } from './add-user/add-user.component';
import { OnboardUserComponent } from './onboard-user/onboard-user.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { AdminApprovalComponent } from './admin-approval/admin-approval.component';
import { OnboardingHistoryComponent } from './onboarding-history/onboarding-history.component';
import { LandingComponent } from './landing/landing.component';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './common/user-management/user-management.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from "./common/auth.guard";

const appRoutes: Routes = [
    {
        path: '', component: LandingComponent
    },
    {
        path: 'addUser',
        component: AddUserComponent, canActivate: [AuthGuard]
    },
    {
        path: 'usermanagement',
        component: UserManagementComponent, canActivate: [AuthGuard],
        children: [
            {
                path: 'manageProject',
                component: ManageProjectComponent, canActivate: [AuthGuard]
            },
            {
                path: 'updateProject',
                component: UpdateProjectComponent, canActivate: [AuthGuard]
            },
            {
                path: 'manageApplication',
                component: ManageApplicationsComponent, canActivate: [AuthGuard]
            },
            {
                path: 'createApplication',
                component: CreateApplicationComponent, canActivate: [AuthGuard]
            },
            {
                path: 'createRole',
                component: CreateRoleComponent, canActivate: [AuthGuard]
            },
            {
                path: 'manageRole',
                component: ManageRoleComponent, canActivate: [AuthGuard]
            },

            {
                path: 'onboardUser',
                component: OnboardUserComponent, canActivate: [AuthGuard]
            },
            {
                path: 'manageUser',
                component: ManageUserComponent, canActivate: [AuthGuard]
            },
            {
                path: 'approvePermission',
                component: AdminApprovalComponent, canActivate: [AuthGuard]
            },
            {
                path: 'onboardingHistory',
                component: OnboardingHistoryComponent, canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'dashboard',
        component: DashboardComponent, canActivate: [AuthGuard]
    },
    {
      path: '**',
      redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
  })

export class AppRoutingModule { }
